import * as React from "react"
import Layout from "../../Layout"
import * as s from "./index.module.less"

const KeyConceptsPage = () => (
  <Layout
    title={"Key Concepts"}
    description={"Key concepts to know before start using our API"}
    pageDescription={"Key concepts to know before start using our API"}
  >
    <div className="row">
      <div className="col-lg-8">
        <table className={s.table}>
          <thead>
            <tr>
              <th>Term</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Space Operator</td>
              <td>An Space Operator is the owner of one or more locations</td>
            </tr>
            <tr>
              <td>Location</td>
              <td>
                A Location is a building with multiple offices and/or floors
              </td>
            </tr>
            <tr>
              <td>Office</td>
              <td>
                Ann office is the smallest unit that can be reserved by the end
                users.
              </td>
            </tr>
            <tr>
              <td>API User</td>
              <td>
                The applications that uses Worksipmly API are referred to as API
                Users.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
)

export default KeyConceptsPage
